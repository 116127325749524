import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import RequireAuth from "./components/RequireAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Spinner } from "react-bootstrap";
import { PostHogProvider } from "posthog-js/react";

const Home = lazy(() => import("./screens/Home"));
const Chat = lazy(() => import("./screens/Chat"));
const Auth = lazy(() => import("./screens/Auth"));
const NotFound = lazy(() => import("./screens/404"));

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_90v3MGFi0",
    userPoolWebClientId: "78e3bbrm09ofvc3s66phd7p8aa",
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: "chat/:id",
    element: (
      <RequireAuth>
        <Chat />
      </RequireAuth>
    ),
  },
  {
    path: "login/",
    element: <Auth />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <PostHogProvider
        apiKey={"phc_mIALOeZUhjGwOOyDv2td8KsQPWjkRNLcFxJ5Xwc77Jo"}
        options={{
          api_host: "https://analytics.getspike.co",
        }}
      >
        <Suspense
          fallback={
            <main className="flex min-h-screen flex-col justify-center items-center">
              <Spinner />
            </main>
          }
        >
          <RouterProvider router={router} />
        </Suspense>
      </PostHogProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
